<template>
<div class="main">
  <router-link to="/">Home</router-link> |
  <router-link to="/new">New Post</router-link>
  <router-view/>
</div>
  
</template>

<style>
body {
  background-color: #f1f1f1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
