<template>
  <div class="home">
    <h1>Posts</h1>
    <div class="posts" >
      <Post v-for="(post, index) in posts" :key="index" :id="post.id" :score="post.score" :type="post.type" :title="post.title" :username="post.username" :content="post.content" />
    </div>
    
  </div>
</template>

<script>
import Post from '@/components/Post.vue'
import axios from "axios"

export default {
  name: 'Home',
  components: {
    Post
  },
  data() {
    return {
      posts: []
    }
  },
  mounted(){
    axios
      .get("https://social-page-worker.abramojo02.workers.dev/posts")
      .then(response => (this.posts = (response.data).sort((a,b) => b.score - a.score )))
  }


}
</script>

<style scoped>
.posts{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: auto;
}
</style>