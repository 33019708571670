<template>
  <div class="post">
    <div class="post-header">
      <span class="title">{{title}}</span>
      <span class="username">by: {{username}}</span>
    </div>
    <div class="content">
      <span v-if="type == 'text'">{{content}}</span>
      <img v-if="type == 'image'" :src="content" />
      <iframe v-if="type == 'youtube'" :src="'https://www.youtube.com/embed/' + content" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="voting">
      <span>Vote</span>
      <span class="vote-btn" @click="downvote">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
        </svg>
      </span>
      {{displayScore}}
      <span class="vote-btn" @click="upvote">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'Post',
  props: {
    title: String,
    username: String,
    type: String,
    content: String,
    id: String,
    score: Number
  },
  data(){
    return {
      displayScore: this.score
    }
  },
  methods: {
    upvote(){
      let data = {
        id: this.id,
        action: "increase"
      }
      this.displayScore += 1
      Axios
        .post("https://social-page-worker.abramojo02.workers.dev/vote", data)
    },
    downvote(){
      let data = {
        id: this.id,
        action: "decrease"
      }
      this.displayScore -= 1
      Axios
        .post("https://social-page-worker.abramojo02.workers.dev/vote", data)
    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.post{
  background-color: #fff;
  padding: 10px;
  margin: 10px;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.post-header{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title {
  font-size: 1.5em;
  overflow: hidden;
}
.username {
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
  max-width: 30ch;
}

.content{
  width: 100%;
  margin-top: 10px;
}

iframe {
  width: 100%;
  aspect-ratio: 16/9;
}

img {
  width: 100%;
}

.voting {
  padding: 10px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.vote-btn {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5em;
  padding: 0 8px;
  }
</style>
